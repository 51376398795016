
import { Component, Vue } from 'vue-property-decorator'
import store from "@/store";
import { UserViewModel } from "@/api-client";

@Component({
name: 'Profile',
})
export default class extends Vue {
    public userForm = {
        address1:"",
        address2:"",
        city:"",
        companyname:"",
        email:"",
        firstname:"",
        fullstate:"",
        lastname:"",
        phonenumberformatted:"",
        postcode:""
    }
    public clonedUser: UserViewModel = {
        displayName: "",
        email: "",
        roleIds: [],
        workshopRoles: [],
        id: "",
        cellNumber: "",
        acceptedPrivicyPolicy: false,
        isEnabled: false,
        isWorkshopUser: false
    }

    public isEdit: Boolean = false
    public isLoading: boolean = true;

    created() {
        this.loadInitialData();
    }

    async loadInitialData() {
        this.clonedUser = await store.dispatch('user/getUserProfile');

        setTimeout(() => {
            if(this.clonedUser.id != "") {
                this.isLoading = false
            }
            else {
                this.loadInitialData();
            }
        }, 1000);
    }

    focus(e: any) {
        let input = e.target
        if(e.target.tagName !== 'LABEL') {
            return;
        }

        input = e.target.nextSibling
        input.querySelector("input").focus()
    }

    saveProfile() {
        this.loadInitialData();
        this.toggleEdit();
    }

    toggleEdit() {
        this.isEdit = !this.isEdit;
        // this.userForm = this.clonedUser.userProfileViewModel;
    }

    updateButtonIcon() {
        return !this.isEdit ? "el-icon-edit" : "el-icon-close";
    }

    updateButtonColour() {
        return !this.isEdit ? "primary" : "info";
    }
}
